.SearchPage {
  .custom-x-scroll {
    // border: solid;
    // cursor: pointer;
    user-select: none;
    overflow-x: scroll;
    overflow-x: scroll;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
      width: 1px; /* Set scrollbar width */
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f0f0f0; /* Set track color */
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      position: relative;
      width: 1px;
      background-color: rgba(23, 23, 23, 0.153);
      height: 6px;
      border-radius: 10px; /* Add rounded corners */
      /* Add a pseudo-element */
      &::after {
        content: "wwwwwwwwwwwwwwwwwwwwwwwwwwww";
        position: absolute;
        z-index: 1000;
        border: solid;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px; /* Make it taller than the track */
        cursor: ns-resize; /* Change the cursor shape */
      }
    }
  }
}
