.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
  }
  
  .logo {
    flex-shrink: 0;
  }
  
  .nav-links {
    margin-left: auto; /* Push the navigation links to the right */
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .menu-options {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  