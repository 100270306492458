.carousel-container{
  // border: solid;
  height: 100%;
  width: 100%;
  .custom-dot-list-style{
    // border: solid red;
    .react-multi-carousel-dot--active{
      button{
        background-color: rgb(53, 53, 53);
      }
    }
    button{
      background-color: #f4f4f4;
    }
  }
}