/* Make sure to import the Anton font */
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
.back-ruge-bg-image{
  background-image: url("../../assets/image/hero-image.png"); /* Replace with your image path */
  background-size: cover; /* Fills the whole page */
  background-repeat: no-repeat; /* Avoids repeating the image */
  background-position: center; /* Centers the image */
  // background: #000;
  position: relative;
}
.LandingPage {
  .hero-section {
    // margin-top: 150px;
    width: 100%;
    height: 780px;

    margin: 0;
    // min-height: 100vh; /* Ensures the body covers the full height */
    .content {
      position: relative;
      z-index: 10; /* Ensures text is above the overlay */
      color: white;
      // padding: 20px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;


  }
  .clip-diamond {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  // border-radius: !important 30px;
}

}



.FooterComponent{
  color: white;
  h3{
    color: rgba(255, 255, 255, 0.5);
    // font-family: Circular Std;
font-size: 16px;
font-weight: 450;
line-height: 26px;
letter-spacing: -0.40799999237060547px;
text-align: left;

  }
  ul{
    li{
      // font-family: Circular Std;
font-size: 20px;
font-weight: 450;
line-height: 26px;
letter-spacing: -0.40799999237060547px;
text-align: left;
margin: 20px 0;
    }
  }

  .icon-container{
    .icon{
      width:48px;
height:48px;
border-radius: 4px;
border: 0.5px 0px 0px 0px;
display: flex;
justify-content: center;
align-items: center;

border: 0.5px solid #FFFFFFB2
    }
  }
  span{
    // font-family: Circular Std;
font-size: 16px;
font-weight: 450;
line-height: 14px;
letter-spacing: -0.40799999237060547px;
text-align: left;

  }
}